import "@fontsource/montserrat";
import React from "react";
import DuckeneersLogo from "../assets/images/duckeneers-logo.png";
import LayoutComponent from "../components/LayoutComponent";
import Seo from "../components/seo";
import "../styles/pages.scss";

const IndexPage: React.FC<{}> = () => {
  return (
    <LayoutComponent hideGreenBox>
      <Seo title="Impressum" />
      <div className="content-box imprint-wrapper">
        <img src={DuckeneersLogo} alt="Duckeneers Logo" />
        <p>
          <strong>Duckeneers GmbH</strong>
          <br />
          Leitzstraße 45
          <br />
          70469 Stuttgart
        </p>
        <p>
          Telefon +49 (0) 711 490 66 138
          <br />
          E-Mail <a href="info@duckeneers.de">info@duckeneers.de</a>
          <br />
          Geschäftsführer Pascal Faber, Bernd Schäflein, Maximilian Wüst
        </p>
        <p>
          Amtsgericht Stuttgart
          <br />
          Registernummer HRB 781389
          <br />
          Ust.-ID-Nr. (beantragt)
        </p>
        <p>
          Die Icons, die auf dieser Homepage verwendet werden sind Google Font
          Icons und können gemäß der{" "}
          <a
            href="https://www.apache.org/licenses/LICENSE-2.0.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            Apache License 2.0
          </a>{" "}
          frei verwendet werden.
        </p>
        <p>Diese Seite nutzt PHP Mailer</p>
      </div>
    </LayoutComponent>
  );
};

export default IndexPage;
